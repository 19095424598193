import React, { Fragment } from 'react'
import { Table } from 'semantic-ui-react'
import Context from './context'

function TableExamplePagination(props) {
    return (
        <Context.Consumer >
            {
                ctx => {
            
                    
                    return (<Table celled className="myTable">
                        <Table.Header>
                            <Table.Row>
                               
                                  <Table.HeaderCell className="cellClass" >#</Table.HeaderCell>
                                  <Table.HeaderCell className="cellClass" >Name</Table.HeaderCell>
                                  <Table.HeaderCell className="cellClass" >Trip Beginning</Table.HeaderCell>
                                  <Table.HeaderCell className="cellClass" >Trip End</Table.HeaderCell>
                                  <Table.HeaderCell className="cellClass" >Trip Duration</Table.HeaderCell>
                                  <Table.HeaderCell className="cellClass" >Mileage</Table.HeaderCell>
                                  <Table.HeaderCell className="cellClass" >Max Speed</Table.HeaderCell>
                                  <Table.HeaderCell className="cellClass" >Total Engine Hours</Table.HeaderCell>
                                  <Table.HeaderCell className="cellClass" >Total Idling</Table.HeaderCell>
                                  <Table.HeaderCell className="cellClass" >Driver</Table.HeaderCell>
                                  <Table.HeaderCell className="cellClass" >Phone</Table.HeaderCell>
                                  <Table.HeaderCell className="cellClass" >Badge</Table.HeaderCell>
                                  <Table.HeaderCell className="cellClass" >Group</Table.HeaderCell>
                             

                            </Table.Row>



                        </Table.Header>

                        <Table.Body>

                            {
                                // ctx.value.selectedTargetType.id === 1 ? // group
                                //     (
                                        ctx.value.newData.length > 0 ?
                                        ctx.value.newData.map(function (value, index) {
                                            //   console.log(value)
                                        return <React.Fragment key={index}>
                                            {
                                                (
                                                    <Table.Row
                                                    style={{
                                                        fontWeight: 'bold',
                                                        backgroundColor:'cornsilk'
                                                    }}
                                            key={index}>
                                            <Table.Cell key={index + value.n + 'a'}>{value.n}</Table.Cell>
                                            <Table.Cell key={index + value.n + 'a1'}>{value.name}</Table.Cell>

                                            <Table.Cell key={index + value.n + 'a2'}>{value.beginTripTime}</Table.Cell>

                                            <Table.Cell className="cellClass" key={index + value.n + 'b1'}>
                                                {value.endTripTime}
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={index + value.n + 'b2'}>
                                                {value.tripDuration}
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={index + value.n + 'c'}>
                                                {value.totalMileage}
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={index + value.n + 'd'}>
                                                {value.maxSpeed}
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={index + value.n + 'e1'}>
                                                {value.totalEH}
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={index + value.n + 'e2'}>
                                               {value.totalIdling}
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={index + value.n + 'e3'}>
                                                {value.driver}
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={index + value.n + 'f'}>
                                                {value.phone}
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={index + value.n + 'f2'}>
                                             {value.badge}

                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={index + value.n + 'f3'}>
                                             {value.group}
                                            </Table.Cell>
                                          
                                            
                                        

                                        </Table.Row>
                                                )
                                            }
                                            {value.r.map(function(v,i){
                                            return <Table.Row
                                            key={i}>
                                            <Table.Cell key={i + v.n + 'a'}>{v.c[0]}</Table.Cell>
                                            <Table.Cell key={i + v.n + 'a1'}>{v.c[1]}</Table.Cell>

                                            <Table.Cell className="cellClass" key={i + v.n + 'b1'}>
                                                {v.c[2].t}
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={i + v.n + 'b2'}>
                                                {v.c[3].t}
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={i + v.n + 'c'}>
                                                {v.c[4]}
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={i + v.n + 'd'}>
                                                {v.c[5]}
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={i + v.n + 'e1'}>
                                                {v.c[6].t}
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={i + v.n + 'e2'}>
                                                -----
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={i + v.n + 'e3'}>
                                                -----
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={i + v.n + 'f'}>
                                                {v.c[7]}
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={i + v.n + 'f2'}>
                                             {v.c[8]}

                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={i + v.n + 'f3'}>
                                            {v.c[9]}
                                            </Table.Cell>
                                            <Table.Cell className="cellClass" key={i + v.n + 'f4'}>
                                            {v.c[10]}
                                            </Table.Cell>
                                            
                                        

                                        </Table.Row>
                                        })
                                    }
                                        
                                        </React.Fragment>
                                            
                                            })
                                            :
                                            null
                                  


                            }

                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>

                            </Table.Row>
                        </Table.Footer>
                    </Table>)
                }
            }
        </Context.Consumer>
    )
}

export default TableExamplePagination