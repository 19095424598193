// import React from 'react'
import styled from 'styled-components'
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const RootDiv = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
max-height:90%;
max-width:90%;
min-width:90%;
max-height:90%;
z-index:1000;

`
const Img = styled.img`
width: 100%;
height:100%;
position:absolute;
top:0;
left:0;
right:0;
bottom : 0;
opacity:0.5;
`
const useStyles = makeStyles({
  root: {
    minWidth: '100%',  
   boxShadow: '10px 10px 36px -19px rgba(0,0,0,0.38)'
   
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const PTitle = styled.p`
font-size:1.5rem;
color:'#0D0D0C';
font-weight:bold;
z-index:1000;
`
const PValue = styled.p`
font-size:1.2rem;
z-index:1000;
`
const IconDiv = styled.div`

`
export default function SimpleCard(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root}>
      <CardContent style={{position:"relative"}}>
      
      <RootDiv>
         <PTitle>{props.title}</PTitle>     
            <PValue style={{
                color:props.bgColor
            }}>{props.value}</PValue>
        <div> {props.icon} </div>
      </RootDiv>
      <Img src={require('../assets/img/path1.svg')}></Img>
      </CardContent>
      
    </Card>
  );
}

