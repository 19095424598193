import React from 'react';
import Context from './context'
import MyTable from './table'
import ExpCard from './ExpCard'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import TotalCard from './totalCard'
import styled from 'styled-components'

const ImgDiv = styled.div`
width:100%;
height:100vh;
position:relative;
display:flex;
align-items:center;
justify-content:center;
padding:25px;
`;

const Img = styled.img`
width:35%;
height:35%;
opacity:0.5;
`

class Report extends React.Component {
    constructor(props) {

        super(props)
        this.state = {

        }
    }


    componentDidMount() {
    }

    render() {
        return (
            <Context.Consumer>
                {
                    ctx => {

                        return (
                            <div>
                                {
                                    ctx.value.newData.length > 0 && ctx.value.isReady?
                                        (
                                            <React.Fragment>
                                                <ExpCard className="ctitle" title='Totals'>

                                                    <Grid
                                                        direction="row"
                                                        justify="space-between"
                                                        alignItems="center"
                                                        container spacing={5}>

                                                        {
                                                            ctx.value.totalsData ? ctx.value.totalsData.map(function (val, index) {
                                                                return (
                                                                    <Grid key={index} style={{

                                                                        backgroundColor: val.bgColor
                                                                    }}

                                                                        item xs={12} sm={12} md={6} lg={4} xl={3}>
                                                                        <Card >
                                                                            <TotalCard
                                                                                title={val.title}
                                                                                value={val.value}
                                                                                icon={val.icon}
                                                                                bgColor={val.bgColor}
                                                                            >
                                                                            </TotalCard>
                                                                        </Card>
                                                                    </Grid>

                                                                )
                                                            })
                                                                :
                                                                null
                                                        }

                                                    </Grid>



                                                </ExpCard>
                                                <ExpCard title='Table Results'>

                                                    <MyTable style={{ fontFamily: 'Cairo' }}></MyTable>

                                                </ExpCard>
                                            </React.Fragment>
                                        )
                                        :
                                      <ImgDiv>
                                      <Img
                                       src={require('../assets/img/reportbg.svg')}></Img>
                                      </ImgDiv>
                                }

                            </div>

                        )
                    }
                }
            </Context.Consumer>
        )
    }
}

export default Report;