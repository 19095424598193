import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components'

const RootDiv = styled.div`
margin:10px;
padding:5px;
`
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: '#f00'
  },
  paper:{
    backgroundColor:'red'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  Typography:{
     root:{
        width:'100%'
     }
  }
  
}));

export default function SimpleExpansionPanel(props) {
  const classes = useStyles();

  return (
    <RootDiv>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.root} className={classes.heading}>{props.title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{
              width:'100%'
          }}>
           {
               props.children
           }
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      
    </RootDiv>
  );
}
