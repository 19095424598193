import React from 'react';
import { BrowserRouter, Route, Switch, Router } from 'react-router-dom';
import './App.css';
// import XLSX from 'xlsx';
import Context from './components/context'
import MyNav from './components/MyNav'
import { saveAs } from 'file-saver';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import SpeedIcon from '@material-ui/icons/Speed';
import StopIcon from '@material-ui/icons/Stop';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

const ExcelJS = require('exceljs');

const fileJSON = require('./assets/files/constData.json')

/* global wialon */
/* global xlsx */


const RES_NAME = fileJSON.resourceName;
const TARGET_TYPES = fileJSON.targetTypes;
class App extends React.Component {

  constructor(props) {
    super(props)
    let startDate = new Date()
    startDate.setHours(0, 0, 0, 0)
    let endDate = new Date()
    endDate.setHours(23, 59, 59, 59)


    this.state = {
      token: 'ffc0faad9eaeb0336fcb6cade3ce2cdc6E1041008BC2AD65545BF32077ACBC977D080D70', // test app
      isAuthorized: false,
      username: '',
      res: [],
      logging: true,
      showAlert: false,
      alertVariant: 'success',
      error: false,
      alertMSG: '',
      resultJSON: [],
      selectedRes: {},
      selectedReport: {},
      selectedObject: {},
      units: [],
      unitGroups: [],
      executing: false,
      exporting: false,
      fromDateVal: startDate,
      toDateVal: endDate,
      fromDateValUnix: this.convertSATToUnix(startDate),
      toDateValUnix: this.convertSATToUnix(endDate),
      targetTypes: TARGET_TYPES,
      selectedTargetType: fileJSON.targetTypes[1], // group is default
      newData: [], // for unit [{},{},....] , for group [[{},{},....],[],[]] 
      tempData: [], // to hold prepared object arrays of final project
      overlayText: 'جاري تسجيل الدخول...',
      loginMode: fileJSON.loginMode,
      isTried: false,
      dataTable: [],
      isReady: false,
      totalsData: []

    }
    this.fromDateOnChange = this.fromDateOnChange.bind(this)
    this.toDateOnChange = this.toDateOnChange.bind(this)
    this.propOpenFunc = this.propOpenFunc.bind(this)
    this.execute = this.execute.bind(this)
    this.onTargetTypeChange = this.onTargetTypeChange.bind(this)
    this.onUnitChange = this.onUnitChange.bind(this)
    this.onUnitGroupChange = this.onUnitGroupChange.bind(this)
    this.export = this.export.bind(this)
    this.setAuthHash = this.setAuthHash.bind(this)
    this.start = this.start.bind(this)
    this.auth1 = this.auth1.bind(this)
  }

  render() {

    return (
      <BrowserRouter basename="/">
        <Context.Provider
          value={{
            value: this.state,
            actions: {
              fromDateOnChange: this.fromDateOnChange,
              toDateOnChange: this.toDateOnChange,
              propOpen: this.propOpenFunc,
              execute: this.execute,
              onTargetTypeChange: this.onTargetTypeChange,
              onUnitChange: this.onUnitChange,
              onUnitGroupChange: this.onUnitGroupChange,
              export: this.export,
              setAuthHash: this.setAuthHash,
              start: this.start,
              auth1: this.auth1
            }
          }}>

          <Switch>
            <Route path='/start' exact component={MyNav} />
            <Route path='/' component={MyNav} />


          </Switch>

        </Context.Provider>
      </BrowserRouter>
    )

  }
  async start(e) {
    //console.log('hash updated now... going to /')
    //await this.setState({authHash:hash})
    window.location.href = "/?authHash=10a7e0a58bb331656daac8bd938d453e";

  }

  async componentDidMount() {
    // this.auth()
  }

  setAuthHash(aHashVal) {

    // console.log('h updated')
    this.setState({ authHash: aHashVal })
    // console.log('s c')
  }
  async auth1(e) {
    // console.log('passed hash')
    // console.log(e)

    this.auth(e)
  }
  async auth(hashOrToken) {
    //await this.setState({ token: JSON.parse(localStorage.getItem('wenk_helper_token')), logging: true })
    wialon.core.Session.getInstance().initSession("https://hst-api.wialon.com");
    // Try to login when component mount
    if (this.state.loginMode === 'token') {
      // console.log('mode : T')
      this.loginWithToken()
    }
    if (this.state.loginMode === 'authHash') {
      // console.log('mode : H')
      this.loginWithHash(hashOrToken)
    }

  }
  loginWithToken() {
    let that = this
    wialon.core.Session.getInstance().initSession("https://hst-api.wialon.com");

    wialon.core.Session.getInstance().loginToken(
      this.state.token,
      "", // try to login
      async code => {
        // login callback
        // if error code - print error message
        this.setState({ isTried: true })
        if (code) {

          that.setState({ showAlert: true, alertVariant: 'error', logging: false, isAuthorized: false, alertMSG: true, error: true, alertMSG: code === 4 ? "دخول غير مخول" : wialon.core.Errors.getErrorText(code) })
          return;
        }
        // console.log('authorized')
        await that.setState({
          alertVariant: 'success',
          overlayText: 'جاري تحميل البيانات....',
          logging: false,
          executing: false,
          error: false,
          showAlert: true,
          isAuthorized: true,
          alertMSG: wialon.core.Session.getInstance().getCurrUser().getName() + ' : تم تسجيل الدخول بنجاح!',
          username: wialon.core.Session.getInstance().getCurrUser(),
          isTried: true
        })

        await that.init()
      }
    );
  }
  loginWithHash(hash) {
    wialon.core.Session.getInstance().initSession("https://hst-api.wialon.com");

    let that = this
    wialon.core.Session.getInstance().loginAuthHash(
      hash,
      "", // try to login
      async code => {
        this.setState({ isTried: true })

        // login callback
        // if error code - print error message
        if (code) {
          that.setState({ showAlert: true, alertVariant: 'error', logging: false, isAuthorized: false, alertMSG: true, error: true, alertMSG: wialon.core.Errors.getErrorText(code) })
          return;
        }
        that.setState({
          alertVariant: 'success',
          overlayText: 'جاري تحميل البيانات....',
          logging: false,
          executing: false,
          error: false,
          showAlert: true,
          isAuthorized: true,
          alertMSG: wialon.core.Session.getInstance().getCurrUser().getName() + ' : تم تسجيل الدخول بنجاح!',
          username: wialon.core.Session.getInstance().getCurrUser(),
          isTried: true
        })

        await that.init()
      }
    );
  }

  init() {
    // console.log('start init')
    let that = this
    var sess = wialon.core.Session.getInstance();

    sess.loadLibrary("resourceReports");
    sess.loadLibrary("resourceDrivers");


    // flags to specify what kind of data should be returned
    let res_flags = wialon.item.Item.dataFlag.base | wialon.item.Resource.dataFlag.reports | wialon.item.Resource.dataFlag.drivers// 64 bit OR
    let unit_flags = 1;
    let unitGroup_flags = 1;

    sess.updateDataFlags( // load items to current session
      [
        { type: "type", data: "avl_resource", flags: res_flags, mode: 0 },
        { type: "type", data: "avl_unit_group", flags: unit_flags, mode: 0 },
        { type: "type", data: "avl_unit", flags: unitGroup_flags, mode: 0 }

      ], // Items specification

      function (code) { // updateDataFlags callback 
        if (code) {

          // console.log("Error: " + wialon.core.Errors.getErrorText(code));
          return; // exit if error code 
        }

        const resourcesArr = sess.getItems("avl_resource"); // get loaded 'avl_resource's items
        // console.log('res is ')
        // console.log(resourcesArr)
        that.setState({ res: resourcesArr })
        that.findResource(resourcesArr)

        const unitGroups = sess.getItems("avl_unit_group");
        const units = sess.getItems("avl_unit");
        // console.log(units)
        // console.log(unitGroups)
        if (unitGroups.length === 0 && units.length === 0) {
          that.setState({ showAlert: true, error: true, alertMSG: 'لا توجد مجموعات او مركبات!', alertVariant: 'error' })
          return;
        }

        that.setState({ units: units, unitGroups: unitGroups, overlayText: '' });


      });
  }

  findResource(res) {

    if (RES_NAME === '*') {
      // console.log("Entering find resource under res_name equal to *")

      for (let i = 0; i < res.length; i++) {
        // console.log('start loop for resources')

        let that = this
        if (res[i].$$user_reports) {
          for (let [key, value] of Object.entries(res[i].$$user_reports)) {

            if (value.n === that.state.selectedTargetType.reportName) { // default selected target type should be group
              // console.log('report catched, resource catched too')
              that.setState({ selectedRes: res[i], selectedReport: value })

              break;
            }
          }
        }
      }
    }


  }



  // may del it
  convertObjToArr(obj) {
    let arr = []
    for (let [key, value] of Object.entries(obj)) {

      arr.push(value)
    }

    return arr;
  }
  execute(e) {
    if (Object.entries(this.state.selectedRes).length === 0 && this.state.selectedRes.constructor === Object) {

      this.setState({ showAlert: true, error: true, alertMSG: 'يرجى اختيار المصدر', alertVariant: 'error' })
      return;
    }
    if (Object.entries(this.state.selectedReport).length === 0 && this.state.selectedReport.constructor === Object) {

      this.setState({ showAlert: true, error: true, alertMSG: 'يرجى اختيار التقرير', alertVariant: 'error' })
      return;
    }
    if (Object.entries(this.state.selectedObject).length === 0 && this.state.selectedObject.constructor === Object) {

      this.setState({ showAlert: true, error: true, alertMSG: 'يرجى اختيار الهدف', alertVariant: 'error' })
      return;
    }

    if (this.state.fromDateValUnix === '' || this.state.toDateValUnix === '') {
      this.setState({ showAlert: true, error: true, alertMSG: ' يرجى اختيار الفترة اولاً', alertVariant: 'error' })
      return;
    }
    let fromDay = this.state.fromDateVal.toLocaleDateString().split('/')[1]
    let toDay = this.state.toDateVal.toLocaleDateString().split('/')[1]

    let interval = {
      from: this.state.fromDateValUnix,
      to: this.state.toDateValUnix,
      flags: wialon.item.MReport.intervalFlag.absolute
    }
    let that = this
    let sess = wialon.core.Session.getInstance();
    let resou = sess.getItem(this.state.selectedRes._id);
    let template = resou.getReport(this.state.selectedReport.id)

    // first i need to set local datetime 

    //this.prepareTempData(this.state.selectedObject);
    this.setState({ isReady: false, executing: true, overlayText: 'جاري تنفيذ التقرير... يرجى الانتظار... قد تستغرف عملية التنفيذ اكثر من 3 دقائق اذا كانت الفترة المحددة طويلة' })
    let localObj = {
      flags: 0,
      formatDate: "%25Y-%25m-%25E %25H:%25M:%25S"

    }
    sess.getRenderer().setLocale(134228528, 'en', localObj, function (code) {
      if (code) {

        that.setState({ showAlert: true, error: true, alertMSG: 'جدول البيانات فارغ, لا توجد بيانات', alertVariant: 'error', executing: false, overlayText: '', isReady: false })
        return;

      }
      resou.execReport(template, that.state.selectedObject._id, 0, interval, function (code, data) {
        if (code) {
          that.setState({ showAlert: true, error: true, alertMSG: wialon.core.Errors.getErrorText(code), alertVariant: 'error' })
          return;
        }
        if (!data.getTables().length) {
          that.setState({ showAlert: true, error: true, alertMSG: 'جدول البيانات فارغ, لا توجد بيانات', alertVariant: 'error', executing: false, overlayText: '', isReady: false })
          return;
        }

        that.setState({ newData: [], tempData: [], overlayText: 'جاري بناء التقرير' })


        if (data.getTables().length != 2) {
          that.setState({ newData: [], tempData: [], overlayText: '', executing: false, isReady: false, showAlert: true, error: true, alertMSG: 'تعذر بناء التقرير بسبب نقص في البيانات', alertVariant: 'error' })

        }
        else {
          that.preBuild(data);
        }

      });
    })
  }

 

  // may del it
  preBuild(dataTable) {

    let tables = dataTable.getTables();
    let that = this
    let ehTableIndex = tables.findIndex(function (e) {
      return e.label.includes(fileJSON.Tables.eh); // engine hours table
    });
    let ehTable = {}
    if (ehTableIndex >= 0) {
      ehTable = tables[ehTableIndex]
    }
    let tripsTableIndex = tables.findIndex(function (e) {
      return e.label.includes(fileJSON.Tables.trips); // Trips table
    });
    let tripsTable = {}
    if (tripsTableIndex >= 0) {
      tripsTable = tables[tripsTableIndex]
    }

    let obj = {
      eh: {
        table: ehTable,
        index: ehTableIndex
      },
      trips: {
        table: tripsTable,
        index: tripsTableIndex
      }
    }
    // get totals data
    this.getTotalData(obj)
    this.fetchData(dataTable, obj);
  }
  getTotalData(obj) {
    // console.log('object of tables to get data is ')
    // console.log(obj)
    this.setState({
      totalsData: [
        {
          title: 'Trips',
          value: obj.trips.table.total ? (obj.trips.table.total.length > 0 ? (obj.trips.table.total[4]) : '-----') : '-----',
          icon: <LinearScaleIcon></LinearScaleIcon>,
          bgColor: '#FF6584',

        },
        {
          title: 'Mileage',
          value: obj.trips.table.total ? (obj.trips.table.total.length > 0 ? (obj.trips.table.total[5]) : '-----') : '-----',
          icon: <TrendingUpIcon></TrendingUpIcon>,
          bgColor: '#FF6B6B'
        },
        // {
        //   title: 'Max Speed',
        //   value: obj.trips.table.total ? (obj.trips.table.total.length > 0 ? (obj.trips.table.total[6]) : '-----') : '-----',
        //   icon: <SpeedIcon></SpeedIcon>,
        //   bgColor: '#EFA700'
        // },
        {
          title: 'Engine Hours',
          value: obj.eh.table.total ? (obj.eh.table.total.length > 0 ? (obj.eh.table.total[2]) : '-----') : '-----',
          icon: <HourglassFullIcon></HourglassFullIcon>,
          bgColor: '#00C17F'
        },
        {
          title: 'Idling',
          value: obj.eh.table.total ? (obj.eh.table.total.length > 0 ? (obj.eh.table.total[3]) : '-----') : '-----',
          icon: <StopIcon></StopIcon>,
          bgColor: '#3F3D56'
        }
      ]
    }, function () {
      // console.log(this.state.totalsData)
    })

  }
  fetchData(data, obj) {

    let ehArr = []
    let tripsArr = []
    let that = this

    let reqObj = {
      type: "range",
      data: {
        from: 0,
        to: obj.eh.table.rows,
        level: 5,
        flat: 0,
        rawValues: 0
      }
    }

    data.selectRows(obj.eh.index, reqObj, function (code, col) {

      if (code && obj.eh.index !== -1) { // means the error is real, then stop the fetching
        that.setState({ showAlert: true, error: true, alertMSG: wialon.core.Errors.getErrorText(code), alertVariant: 'error' })
        return;
      }

      ehArr = col;
      // console.log(' eh array is : ')
      // console.log(ehArr)
      // now get second table
      reqObj = {
        type: "range",
        data: {
          from: 0,
          to: obj.trips.table.rows,
          level: 5,
          flat: 0,
          rawValues: 0
        }
      }
      data.selectRows(obj.trips.index, reqObj, function (code, col) {

        if (code && obj.trips.index !== -1) { // means the error is real, then stop the fetching
          that.setState({ showAlert: true, error: true, alertMSG: wialon.core.Errors.getErrorText(code), alertVariant: 'error' })
          return;
        }

        tripsArr = col;
        // now get second table
        // console.log('trips arr is : ')
        // console.log(tripsArr)
        if (that.state.selectedTargetType.id === 1) // group
          that.buildG(tripsArr, ehArr);

        if (that.state.selectedTargetType.id === 0) // unit
          that.buildU(tripsArr, ehArr);

      });
    });

  }
  buildG(trips, ehArr) {
    let dt = []
    if (trips.length === 0) {
      this.setState({ showAlert: true, error: true, alertMSG: 'No trips found!', alertVariant: 'info' })
      return;
    }
    // here we go
    for (let i = 0; i < trips.length; i++) {
      let c = trips[i].c;
      // find eh
      let ehObj = this.findObjInEH(c[1], ehArr)
      // console.log('this object jas been found :')
      // console.log(ehObj)
      // console.log('is not it ?')

      // console.log('now finding driver info for each trip')
      let driverInfo = null
      if (trips[i].r.length > 0) {
        for (let k = 0; k < trips[i].r.length; k++) {
          driverInfo = this.findDriverInfo(trips[i].r[k].c[7]); // pass driver name from details
          trips[i].r[k].c.push(driverInfo ? driverInfo.p : '------')
          trips[i].r[k].c.push(driverInfo ? (driverInfo.jp ? driverInfo.jp['Badge ID'] || '------' : '------') : '------')
          trips[i].r[k].c.push(driverInfo ? (driverInfo.jp ? driverInfo.jp['Group Name'] || '------' : '------') : '------')
        }


      }
      let tempObj = {
        n: c[0], // numbering
        name: c[1], // unit Name
        beginTripTime: '-----',
        endTripTime: '-----',
        tripDuration: c[4], //trip duration
        totalMileage: c[5],
        maxSpeed: c[6].t, // max speed
        driver: '-----',
        // total Engine hours and total idling will be brought from eh table table
        totalEH: ehObj.c[2],
        totalIdling: ehObj.c[3],
        phone: '-----',
        badge: '-----',
        group: '-----',
        r: trips[i].r // will be brought from eh tables 

      };

      dt.push(tempObj)

    }
    // console.log('final temp array is ')
    // console.log(dt)
    this.setState({ newData: dt, tempData: [], overlayText: '', executing: false, isReady: true, showAlert: false, error: false, alertMSG: '', alertVariant: 'error' })

  }

  buildU(trips, ehArr) {
    let dt = []
    if (trips.length === 0) {
      this.setState({ showAlert: true, error: true, alertMSG: 'No trips found!', alertVariant: 'info' })
      return;
    }
    if (ehArr.length === 0) {
      this.setState({ showAlert: true, error: true, alertMSG: 'No engine hours data found!', alertVariant: 'info' })
      return;
    }
    if (trips.length > 1) {
      this.setState({ showAlert: true, error: true, alertMSG: 'Too much trips found!', alertVariant: 'info' })
      return;
    }
    if (ehArr.length > 1) {
      this.setState({ showAlert: true, error: true, alertMSG: 'noToo much engine hours data found!', alertVariant: 'info' })
      return;
    }
    // here we go
    let tempObj = {
      n: '1', // numbering
      name: this.state.selectedObject.$$user_name, // unit Name
      beginTripTime: '-----',
      endTripTime: '-----',
      tripDuration: trips[0].c[4], //trip duration
      totalMileage: trips[0].c[5],
      maxSpeed: trips[0].c[6].t, // max speed
      driver: '-----',
      // total Engine hours and total idling will be brought from eh table table
      totalEH: ehArr[0].c[2],
      totalIdling: ehArr[0].c[3],
      phone: '-----',
      badge: '-----',
      group: '-----',
      r: [] // will be brought from eh tables 

    };
    if (trips[0].r.length > 0) {
      for (let i = 0; i < trips[0].r.length; i++) {
        // let c = trips[0].r[i].c
        trips[0].r[i].c[1] = ''
        let driverInfo = this.findDriverInfo(trips[0].r[i].c[7])
        // console.log('driver data is ')
        // console.log(driverInfo)
        trips[0].r[i].c.push(driverInfo ? driverInfo.p : '------')
        trips[0].r[i].c.push(driverInfo ? (driverInfo.jp ? driverInfo.jp['Badge ID'] || '------' : '------') : '------')
        trips[0].r[i].c.push(driverInfo ? (driverInfo.jp ? driverInfo.jp['Group Name'] || '------' : '------') : '------')

      }
      tempObj.r = trips[0].r;
      dt.push(tempObj)
    }
    else {
      this.setState({ newData: [], showAlert: true, error: true, alertMSG: 'No trips details found!', alertVariant: 'info' })
      return;
    }

    // console.log('final temp array in unit state is ')
    // console.log(dt)
    this.setState({ newData: dt, tempData: [], overlayText: '', executing: false, isReady: true, showAlert: false, error: false, alertMSG: '', alertVariant: 'error' })

  }
  findObjInEH(name, ehArr) {
    let object = null;
    object = ehArr.find(
      (e) => {
        return e.c[1] === name
      }
    )
    return object;
  }

  findDriverInfo(name) {
    let driver = null;
    let driverArr = this.convertObjToArr(this.state.selectedRes.$$user_drivers);

    driver = driverArr.find(
      e => {
        return e.n === name;
      }
    )
    // console.log('driver is ')
    // console.log(driver)

    return driver;
  }
  appendData(detailsArr) {
    let ind = 6; // default for group json schema
    if (this.state.selectedTargetType.id === 0)// unit
    {
      ind = 5;
    }
    else {
      ind = 6;
    }
    for (let i = 0; i < detailsArr.length; i++) {
      let driverName = detailsArr[i].c[ind];
      // find details of driver name in drivers list
      let driverArr = this.convertObjToArr(this.state.selectedRes.$$user_drivers);

      let driver = driverArr.find(
        e => {
          return e.n === driverName;
        }
      )
      if (driver) {
        detailsArr[i].c.push(driver)
      }
    }

  }





  fromDateOnChange(e) {

    let from = this.convertSATToUnix(e)
    if (!this.state.fromDateVal === '') {

      if (e) {
        if (this.compareTime(from, this.state.toDateValUnix)) {

          this.setState({ fromDateValUnix: from, fromDateVal: e })

        }
        else {
          this.setState({ showAlert: true, error: true, alertMSG: 'يجب ان تكون الفترة المحددة  صحيحة' })
        }

      }
    }
    else {
      this.setState({ fromDateValUnix: from, fromDateVal: e })

    }



  }
  toDateOnChange(e) {

    let to = this.convertSATToUnix(e)
    if (e) {
      if (this.compareTime(this.state.fromDateValUnix, to)) {

        this.setState({ toDateValUnix: to, toDateVal: e })

      }
      else {
        this.setState({ showAlert: true, error: true, alertMSG: 'يجب ان تكون الفترة المحددة  صحيحة' })
      }

    }

  }
  convertSATToUnix(sat) {
    let longVal = Math.floor(new Date(sat).getTime() / 1000)
    return longVal
  }
  compareTime(from, to) {
    return from <= to
  }
  propOpenFunc(e) {
    this.setState({ showAlert: !this.state.showAlert, alertMSG: '', error: false })
  }

  async onTargetTypeChange(e) {
    if (e) {
      let selObj = e.id === 0 ? this.state.units.length > 0 ? this.state.units[0] : {}
        :
        this.state.unitGroups.length > 0 ? this.state.unitGroups[0] : {}

      await this.setState({ selectedTargetType: e, newData: [], selectedObject: selObj })
      this.findResource(this.state.res)

    }


  }

  onUnitChange(e) {
    // console.log(e)
    if (e) {
      this.setState({ selectedObject: e, selectedTargetType: fileJSON.targetTypes[0] })
      // this.prepareTempData(e)

    }
  }

  onUnitGroupChange(e) {
    // console.log(e)
    if (e) {
      this.setState({ selectedObject: e, selectedTargetType: fileJSON.targetTypes[1] })
      // this.prepareTempData(e)
    }

  }

  styleCell(fontSize,osBold,fontName) {
    let style = {
      font: { size: 10, bold: false, name: 'Arial' },
      alignment: { vertical: 'middle', horizontal: 'center' },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    }
    return style;

  }


  export(e) {
    let workbook = new ExcelJS.Workbook();

    //Creating Sheet for that particular WorkBook
    let sheetName = 'Details';
    let sheet = workbook.addWorksheet(sheetName, {
      pageSetup: { paperSize: 9, orientation: 'landscape' }// 9 is A4 page
      ,
      properties: { showGridLines: true }
    });
   
    sheet.getRow(1).values = ['No','Name','Trip Beginning','Trip End','Trip Duration','Mileage','Max Speed','Total Engine Hours','Total Idling','Driver','Phone','Badge ID','Group Name']
    sheet.columns = [
      { key: "No", width: 3 },
      { key: "Name", width: 30 },
      { key: "Beginning", width: 20 }, // c
      { key: "End", width: 20 }, // d
      { key: "TripDuration", width: 20 }, // d
      { key: "Mileage", width: 10 },  // f
      { key: "MaxSpeed", width: 10 },  // f
      { key: "TotalEngineHours", width: 10 },  // e
      { key: "TotalIdling", width: 10 },  // e
      { key: "Driver", width: 30 }, // g
      { key: "Phone", width: 15 }, // h
      { key: "BadgeID", width: 10 },// i
      { key: "GroupName", width: 15 } // j
    ]

    let fn = "EHTDDReport " + this.state.selectedObject.$$user_name + new Date(this.state.fromDateVal).toLocaleDateString()

    let that = this
    let counter = 2
    this.state.newData.map(function (val, index) {

      sheet.addRow({
        No: val.n,
        Name: val.name,
        Beginning: val.beginTripTime,
        End: val.endTripTime,
        TripDuration:val.tripDuration,
        Mileage: val.totalMileage,
        MaxSpeed:val.maxSpeed,
        TotalEngineHours: val.totalEH,
        TotalIdling: val.totalIdling,
        Driver: val.driver,
        Phone: val.phone,
        BadgeID: val.badge,
        GroupName: val.group
      });

      sheet.getRow(counter).eachCell({ includeEmpty: true }, function (cell, colNumber) {
        cell.style = {
          font: { size: 10, bold: true, name: 'Arial' }
        }
      });
      counter++;
      val.r.map(function(v,i){
        sheet.addRow({
          No: v.c[0],
          Name: v.c[1],
          Beginning: v.c[2].t,
          End: v.c[3].t,
          TripDuration:v.c[4],
          Mileage: v.c[5],
          MaxSpeed:v.c[6].t,
          TotalEngineHours:'-----',
          TotalIdling: '-----',
          Driver: v.c[7],
          Phone: v.c[8],
          BadgeID: v.c[9],
          GroupName: v.c[10]
        });
        counter++;
      })
   
    })
       // now add total
       sheet.addRow({
        No:'',
        Name:'',
        Beginning: '',
        End:'',
        TripDuration:that.state.totalsData[0].value,
        Mileage: that.state.totalsData[1].value,
        MaxSpeed: that.state.totalsData[2].value,
        TotalEngineHours:  that.state.totalsData[3].value,
        // TotalIdling:  that.state.totalsData[4].value,
        Driver:'',
        Phone: '',
        BadgeID:'',
        GroupName: ''
      });
      sheet.getRow(counter).eachCell({ includeEmpty: true }, function (cell, colNumber) {
        cell.style = {
          font: { color: {argb: 'FFC030300'} ,size: 11, bold: true, name: 'Arial' }
        }
      });

    let fileName = fn + ".xlsx";
    let str = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: str });
      saveAs(blob, fileName);
    });


  }

}

export default App;
